import { HttpClient, HttpHeaders,HttpErrorResponse } from  '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class HttpServiceService {

  private urlGetImagen=  environment.apiUrl+ "/v2/payment/getRestrictions/"
  private urlGenCupon =  environment.apiUrl+ "/v2/payment/qrcodesdk/"
  


  constructor(private http: HttpClient) { }

   obtenerImagen(accessTokenP:string, idBatchP:string): Observable<any>{
    console.log(environment.apiUrl)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'accessToken': accessTokenP,
        'batchId': idBatchP
      })
    };
 
    return this.http.get<any>(this.urlGetImagen, httpOptions);
  }

  generarCupon(accessTokenP:string, idBatchP:string,  typeP:number, phoneP:string){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'accessToken': accessTokenP,
        'batchId': idBatchP
      }),
      
    };
    return this.http.post<any>(this.urlGenCupon,{phone: phoneP, type:typeP}, httpOptions).subscribe({
      next: data => {
        if (data.code==="01"){
            try{
              var dummyQR = document.getElementById('dummyQR') as HTMLBodyElement; 
              dummyQR.innerText=data.data[0].qrcodeSms[0].url;
            }
            catch{

            }
            const button = document.getElementById('btGen') as HTMLInputElement; 
            button.disabled=false
            button.style.backgroundColor="#2c7abf"
            var genCupon = document.getElementById('divGenerarCupon') as HTMLDivElement; 
            genCupon.style.display="none";
            var divKO = document.getElementById('divResultadoKO') as HTMLDivElement; 
            divKO.style.display="none";
            var divOK = document.getElementById('divResultadoOK') as HTMLDivElement; 
            divOK.style.display="block";            
            var txtResult = document.getElementById('txResultadoOK') as HTMLBodyElement; 
            txtResult.innerText="Por favor, presenta el cupón en el parking para poder de obtener el descuento sobre tu estancia";
        }
        else {
          const button = document.getElementById('btGen') as HTMLInputElement; 
          button.disabled=false
          button.style.backgroundColor="#2c7abf"
          var genCupon = document.getElementById('divGenerarCupon') as HTMLDivElement; 
          genCupon.style.display="none";
          var divOK = document.getElementById('divResultadoOK') as HTMLDivElement; 
          divOK.style.display="none";    
          var divKO = document.getElementById('divResultadoKO') as HTMLDivElement; 
          divKO.style.display="block";
          var txtResult = document.getElementById('txResultadoKO') as HTMLBodyElement; 
          txtResult.innerText="Error code: " + data.code + "- idProceso: "+ idBatchP;
        }
      },
      error: error => {
          const button = document.getElementById('btGen') as HTMLInputElement; 
          button.disabled=false
          button.style.backgroundColor="#2c7abf"
          var genCupon = document.getElementById('divGenerarCupon') as HTMLDivElement; 
          genCupon.style.display="none";
          var divOK = document.getElementById('divResultadoOK') as HTMLDivElement; 
          divOK.style.display="none";    
          var divKO = document.getElementById('divResultadoKO') as HTMLDivElement; 
          divKO.style.display="block";
          var txtResult = document.getElementById('txResultadoKO') as HTMLBodyElement; 
          txtResult.innerText="Exception: idProceso: "+ idBatchP;
      }
  })

  }

}



